import { graphql } from 'gatsby';
import { arrayOf, shape, string } from 'prop-types';
import React from 'react';
import BEMHelper from 'react-bem-helper';
import { useTranslation } from 'react-i18next';
//import parse from 'html-react-parser';
import parse from 'html-react-parser';

import { Button } from '../../../common/button';
import { pictureFluidType } from '../../../../types/picture';
import { Content } from '../../../common/content';
import { FluidPicture } from '../../../common/picture';
import { getLinkProps } from '../utils';
import './xl.scss';

const bem = new BEMHelper({
  name: 'content-teaser-xl',
});

export const TeaserXL = ({
                           id,
                           headline,
                           description,
                           image: [image],
                           reference,
                         }) => {
  const { t } = useTranslation();
  const color = reference.element?.color || reference.element?.departments[0]?.color;
  const output = headline.replace(new RegExp('(Care)'), `<span class=${bem('highlight').className}>$1</span>`);

  return (
    <Content id={id} {...bem()} observe useOwnSpacing>
      <div {...bem('details', { [`color-${color}`]: color })}>
        <h2 {...bem('headline')}>{parse(output)}</h2>
        <p {...bem('description')}>{description}</p>
        <Button
          {...bem('link')}
          {...getLinkProps(reference)}
          title={reference.title ? reference.title : t('Read more about "{{ topic }}"', { topic: headline })}
        >
          {reference.text || t('Read more')}
        </Button>
      </div>
      <FluidPicture
        {...image}
        maxWidth={1920}
        widths={[480, 960, 1920]}
        role="presentation"
        {...bem('image')}
      />
    </Content>
  );
};

TeaserXL.propTypes = {
  id: string.isRequired,
  headline: string.isRequired,
  description: string.isRequired,
  image: arrayOf(pictureFluidType).isRequired,
  reference: shape({
    url: string,
    text: string,
    target: string,
    title: string,
    element: shape({
      slug: string.isRequired,
      departments: arrayOf(shape({
        slug: string.isRequired,
        color: string.isRequired,
      })),
    }),
  }).isRequired,
};

export const fragment = graphql`
  fragment TeaserXLContentFragment on CraftGQL_pageContents_pageTeaserXL_BlockType {
    id
    headline
    description
    image {
      ...Picture
      fluid_0: url(width: 480, mode: "crop", position: "") # 0.25
      fluid_1: url(width: 960, mode: "crop", position: "") # 0.5
      fluid_2: url(width: 1920, mode: "crop", position: "") # max width
    }
    reference {
      url
      text
      target
      title
      element {
        ...on CraftGQL_pages_pages_Entry {
          __typename
          slug: pageSlug
          departments: pageDepartments {
            slug
            ...on CraftGQL_departments_department_Entry {
              color: departmentColor
            }
          }
        }

        ...on CraftGQL_departments_department_Entry {
        __typename
          slug,
          color: departmentColor
        }

        ...on CraftGQL_products_product_Entry {
          __typename
          slug
          departments: productDepartment {
            ...on CraftGQL_departments_department_Entry {
              slug
              color: departmentColor
            }
          }

          variant: productVariants(limit: 1) {
            ...on CraftGQL_variants_variants_Entry {
              slug
            }
          }
        }
      }
    }
  }
`;
